import axios from "@/services/axios-instance";

export default {
  async getMarketers() {
    const response = await axios.get('/v1/marketers/');
    return response.data
  },

  async getDonorsByPage(data) {
    const response = await axios.get('/v1/buying/donors/', {params: data});
    return response.data
  },

  async getDonors() {
    const response = await axios.get('/v1/buying/donors/select-all/');
    return response.data
  },

  async editDonor(id, data) {
    const response = await axios.patch(`/v1/buying/donors/${id}/`, data);
    return response.data
  },

  async getDonorOptions() {
    const response = await axios.get('/v1/buying/donor-options/');
    return response.data
  },

  async getDonorTypes() {
    const response = await axios.get('/v1/buying/donor-types/');
    return response.data
  },

  async checkDonor(name) {
    const response = await axios.get('/v1/buying/donors/research/', { params: { name: name } });
    return response.data
  },

  async getPaymentMethod() {
    const response = await axios.get('/v1/buying/payment-methods/');
    return response.data
  },

  async getPlatformTypes() {
    const response = await axios.get('/v1/buying/platform-types/');
    return response.data
  },

  async getPromos() {
    const response = await axios.get('/v1/buying/promos/');
    return response.data
  },

  /*----------------------------Summary----------------------------*/
  async getSummary(data){
    const response = await axios.get('/v1/buying/summary/', {params: data});
    return response.data
  },

  /*----------------------------Summary by model----------------------------*/
  async getModelsSummary(data){
    const response = await axios.get('/v1/buying/models-summary/', {params: data});
    return response.data
  },

  /*----------------------------Romi----------------------------*/
  async getRomi(data){
    const response = await axios.get('/v1/buying/negative-romi/', {params: data});
    return response.data
  },

  /*----------------------------Ads----------------------------*/
  async getProblemStatuses() {
    const response = await axios.get('/v1/buying/problem-statuses/');
    return response.data
  },
  async getAds(data) {
    const response = await axios.get('/v1/buying/', {params: data});
    return response.data
  },

  async getAdById(id) {
    const response = await axios.get(`/v1/buying/${id}/`);
    return response.data
  },

  async addPromo(data) {
    const response = await axios.post('/v1/buying/', data);
    return response.data
  },

  async editPromo(id, data) {
    const response = await axios.patch(`/v1/buying/${id}/`, data);
    return response.data
  },

  async deletePromo(id) {
    const response = await axios.delete(`/v1/buying/${id}/`);
    return response.data
  },

  async copyPromo(id, data) {
    const response = await axios.post(`/v1/buying/${id}/copy/`, data);
    return response.data
  },

  async swapPromo(id, data) {
    const response = await axios.post(`/v1/buying/${id}/swap/`, data);
    return response.data
  },

  /*----------------------------Black list----------------------------*/
  async getBlackList(data) {
    const response = await axios.get('/v1/buying/black-list/', {params: data});
    return response.data
  },

  async addBlackListItem(data) {
    const response = await axios.post('/v1/buying/black-list/', data);
    return response.data
  },

  async editBlackListItem(id, data) {
    const response = await axios.patch(`/v1/buying/black-list/${id}/`, data);
    return response.data
  },

  async deleteBlackListItem(id) {
    const response = await axios.delete(`/v1/buying/black-list/${id}/`);
    return response.data
  },
}